import {
  CAREADO_USER_GROUPS,
  CNC_USER_GROUPS,
  CORTE_USER_GROUPS,
  MachineStep,
  OPERATION_ALERT_CODES,
} from './constants'
import { Property } from 'src/graphql/types'
import RelativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import { isBoolean } from 'lodash'
import { AlertSettings } from '@valiot/types'

dayjs.extend(RelativeTime)

export const getPropNumber = (value: string | undefined) => {
  const numberFormat = Number(value)
  return !isNaN(numberFormat) ? numberFormat.toLocaleString() : undefined
}

/**
 * This function helps to get the resource's state base on current step and if there is an active stop
 * @param step => current step in machine
 * @param activeStop => if resource is in stop
 * @returns string => current resource state
 */
export const getMachineStatus = (step: number, activeStop: boolean) => {
  if (activeStop) return 'En Paro'
  if (step < MachineStep.waitingForSetup) return 'En Espera'
  if (step === MachineStep.waitingForSetup) return 'En Setup'
  if (step > MachineStep.waitingForSetup) return 'Setup'
  return ''
}

/**
 * This function helps to get the validating setup state base on current step
 * @param step => current step in machine
 * @returns string => current validating setup state
 */
export const getValidatingSetupState = (step: number) => {
  if (step < MachineStep.validatingSetup) return 'En Espera'
  if (step === MachineStep.validatingSetup) return 'Validando'
  return 'Validado'
}

/**
 * This function helps to get the dimensional test state base on last dimensional test date
 * @param lastDimensionalTest => date of last dimensional test
 * @returns string => current dimensional test state
 */
export const getDimensionalTestStatus = (lastDimensionalTest: string | null) => {
  if (lastDimensionalTest) return dayjs(lastDimensionalTest).fromNow()
  return 'Listo para Validar'
}

export const getProperty = ({ code, properties }: { code: string; properties: Property[] | undefined }) => {
  return properties?.find((property) => property?.propertyCatalogue?.code === code)
}

/**
 *
 *  This function helps to get the total pieces in box based on box being a record with the schema {data: {[planId]: quantity}}
 *
 * @param box
 * @returns number => total pieces in box
 */
export const getTotalPiecesInBox = (box: { data: Record<string, string | number> }) => {
  if (box.data.quantity && !isNaN(Number(box.data.quantity))) {
    return box.data.quantity
  }

  return Object.keys(box.data).reduce((sum, key) => {
    // Check if the key contains only numeric values
    if (!isNaN(Number(box.data[key])) && !isBoolean(box.data[key])) {
      return sum + Number(box.data[key])
    }

    return sum
  }, 0)
}

/**
 *
 * convert key to number and return the first key that is not NaN
 *
 */
export const fetchObjectKeyOfTypeNumber = (object: Record<string, any>) => {
  const maybeNumber = Object.keys(object).find((key) => !isNaN(Number(key)))
  if (!maybeNumber) return undefined
  return Number(maybeNumber)
}

const getOperationGroupsWithCodes = (groups: string[], codes: string[]): AlertSettings['byRoles'] => {
  const result: AlertSettings['byRoles'] = {}

  for (const groupCode of groups) {
    result[groupCode] = { codes }
  }

  return result
}

export const getSettingsAlertsByRole = (): AlertSettings['byRoles'] => {
  return {
    Admin: { codes: [] },
    Calidad: { codes: ['EMCD', 'LIBC', 'LIBC', 'LIBC', 'ORUNF', 'MPR', 'MPLC', 'MPREC'] },
    Supervisor: {
      codes: [
        'INAC',
        'FMAQ',
        'FM',
        'FC',
        'CINS',
        'CMD',
        'VINS',
        'MAMCOR',
        'MAMPREV',
        'LIBL',
        'INAC',
        'FM',
        'FC',
        'CINS',
        'CMR',
        'MAMCOR',
        'MAMPREV',
        'LIBL',
        'INACT',
        'SOLMP',
        'FMP',
        'FCPC',
        'FC',
        'CINS',
        'CMR',
        'MAMCVO',
        'MAMPVO',
        'LIBL',
        'LIBCON',
        'ORUNF',
        'MPE',
        'MAMPREV',
        'MAMPRED',
        'MPE',
        'CE',
        'CT',
        'MET',
        'CTG',
        'MPR',
        'MACHINE_STOPPED_ALERT',
      ],
    },
    Mantenimiento: {
      codes: [
        'FMAQ',
        'MAMCOR',
        'MAMPREV',
        'FMAQ',
        'MAMCOR',
        'MAMPREV',
        'FMAQ',
        'FMP',
        'MAMCVO',
        'MAMPVO',
        'MAMPREV',
        'MAMPRED',
        'MAMVAL',
      ],
    },
    Manufactura: {
      codes: ['CMP', 'CMP', 'CMP', 'VINS', 'PAPF'],
    },
    Montacarguista: {
      codes: ['RETCON', 'FM', 'FC', 'RETCON', 'SOLMP', 'FCPC', 'FC', 'LIBCON'],
    },
    Gruista: {
      codes: ['SOLMP'],
    },
    ['Planeación']: {
      codes: ['ORUNF', 'MPR'],
    },
    ['Insumos Herramentales']: {
      codes: ['SOLINS'],
    },
    ...getOperationGroupsWithCodes(CORTE_USER_GROUPS, OPERATION_ALERT_CODES),
    ...getOperationGroupsWithCodes(CAREADO_USER_GROUPS, OPERATION_ALERT_CODES),
    ...getOperationGroupsWithCodes(CNC_USER_GROUPS, OPERATION_ALERT_CODES),
  }
}

export const isNullish = (value: any): value is null | undefined => value === null || value === undefined
