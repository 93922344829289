export const TRACKS_AND_CAGES_CNC_CODES = [
  'CNC_13',
  'CNC_14',
  'CNC_17',
  'CNC_18',
  'CNC_19',
  'CNC_20',
  'CNC_21',
  'CNC_22',
  'CNC_23',
  'CNC_31',
  'CNC_32',
  'CNC_39',
  'CNC_40',
  'CNC_44',
  'CNC_45',
  'MONTA_01',
  'MONTA_02',
]

export const StopReasonCodes = {
  shifts: ['DESCANSO', 'ENTREGA_TURNO', 'ALMUERZO', 'NO_LABORAL'],
}

export const TIMELINE_SCALE = 12

export const PLAN_STATUS_TEXT = {
  CANCELLED: 'Cancelado',
  FINISHED: 'Finalizado',
  PAUSED: 'Pausado',
  PENDING: 'Pendiente',
  RUNNING: 'Corriendo',
}

export const RecordDefinition = {
  /**
   * Operation Screens
   */
  workOrder: 'PROMEBA_GUA_CEL_WORK_ORDER',
  historicalWorkOrder: 'PROMEBA_GUA_CEL_HISTORICAL_WORK_ORDER',
  requestTooling: 'PROMEBA_GUA_CEL_REQUEST_TOOLING',
  requestRawMaterialPickup: 'PROMEBA_GUA_CEL_REQUEST_RAW_MATERIAL_PICKUP',
  scrap: 'PROMEBA_GUA_CEL_SCRAP',
  setup: 'PROMEBA_GUA_CEL_SETUP',
  dimensionalTest: 'PROMEBA_GUA_CEL_DIMENSIONAL_TEST',
  container: 'PROMEBA_GUA_CEL_BOX',
  pieceValidation: 'PROMEBA_GUA_CEL_PIECE_VALIDATION',
  containerValidation: 'PROMEBA_GUA_CEL_CONTAINER_VALIDATION',
  inspectionPoints: 'PROMEBA_GUA_CEL_INSPECTION_POINTS',
  skuConfig: 'PROMEBA_SKU_CONFIG',
  transitRequest: 'CONTAINER_TRANSIT_REQUEST',
  approvedToolingRequest: 'PROMEBA_GUA_CEL_APPROVED_TOOLING_REQUEST',
  /**
   * Maintenance Orders
   */
  maintenanceOrders: 'PROMEBA_MEX_QRO_MANTENIMIENTO_ORDENES',
  actionsCatalogue: 'PROMEBA_MEX_QRO_CATALOGO_ACCIONES',
  resourcesCatalogue: 'PROMEBA_MEX_QRO_CATALOGO_RECURSOS',
  reasonsCatalogue: 'PROMEBA_MEX_QRO_CATALOGO_MOTIVOS',
  systemCatalogue: 'PROMEBA_MEX_QRO_CATALOGO_SISTEMAS',
  historicalCages: 'PROMEBA_MEX_QRO_HISTORICO_JAULAS',
} as const

export const MachineCode = {
  cutting: 'CORTE',
  careado: 'CAREADO',
  cnc: 'CNC',
} as const

export const MachineStep = {
  waitingForPlan: 1,
  waitingForSetup: 2,
  validatingSetup: 3,
  waitingToRunOrder: 4,
  runningOrder: 5,
}

export const ToolingStatus = {
  available: 'AVAILABLE',
  inUse: 'IN USE',
  inFloor: 'IN FLOOR',
} as const

export const SECONDS_TO_RESTART_DIMENSIONAL_TIMER = 1800
export const MaterialProperties = {
  operation: 'OPERACION',
  businessArea: 'OPERACION_PROMEBA',
  program: 'PROGRAMA',
}

export const PlanStatusText = new Map([
  ['status.pending-late', 'Pendiente con retraso'],
  ['status.running-overdue', 'Corriendo con retraso'],
  ['status.paused-and-late', 'Pausado con retraso'],
  ['status.finished-late', 'Finalizado con retraso'],
  ['status.paused-on-time', 'Pausado a tiempo'],
  ['status.pending-on-time', 'Pendiente a tiempo'],
  ['status.running-on-time', 'Corriendo a tiempo'],
  ['status.finished-on-time', 'Finalizado a tiempo'],
  ['status.unknown', 'Desconocido'],
])

export const OPERATION_ALERT_CODES = ['VINS']
export const CORTE_USER_GROUPS = ['Corte 1', 'Corte 2', 'Corte 3', 'Corte 4', 'Corte 5', 'Corte 6', 'Corte 7']
export const CAREADO_USER_GROUPS = [
  'Careado 1',
  'Careado 2',
  'Careado 3',
  'Careado 4',
  'Careado 5',
  'Careado 6',
  'Careado 7',
]
export const CNC_USER_GROUPS = Array.from({ length: 41 }, (_, i) => `CNC ${i + 1}`)
